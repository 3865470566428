@import '../../styles/colors';
@import '../../styles/vars';

.dialogContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;
    background: $primary-blackout-color;
  }
}

.dialog {
  background-color: $primary-background-color;
  width: 100%;
  max-height: $dialogMaxHeight;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  min-height: 0;
  max-width: 700px;
  position: relative;
  z-index: 100;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.07),
    -4px -4px 10px rgba(0, 0, 0, 0.07);
  border-radius: $primary-border-radius;

  .head {
    background-color: $primary-background-color;
    flex: 0 0 $titleHeight;
    padding: 0 $primaryPadding;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    color: $primary-font-color;
    border-bottom: solid 2px $primary-border-color;

    font-family: $secondary-font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.01em;

    border-top-left-radius: $primary-border-radius;
    border-top-right-radius: $primary-border-radius;
  }

  .body {
    padding: $primaryPadding;
    overflow-y: auto;
    min-height: 80px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    padding: $primaryPadding;

    button {
      margin-left: 10px;
    }

    .errorFeedback {
      margin-right: auto;
      color: $warning-font-color;
    }

    .canceledButton {
      margin-right: auto;
    }
  }
}
