@import '../../styles/vars';

%head-font {
  font-family: $secondary-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #000;
}

%primary-font {
  color: $light-font-color;
  font-family: $secondary-font-family;
  font-style: normal;
  font-weight: 600;
  font-size: $normalSizeText;
  line-height: 18px;
}

%secondary-font {
  color: $primary-font-color;
  font-family: $secondary-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: $normalSizeText;
}

.table {
  $border: solid 1px $primary-border-color;
  border: $border;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: $primary-border-radius;
  // box-shadow: $primary-box-shadow;

  table {
    text-align: left;
    vertical-align: middle;
    border-collapse: collapse;
    width: 100%;
    background-color: $primary-background-color;
    border-spacing: 0;

    tr {
      height: 50px;
    }

    button {
      width: 100%;
      max-width: 120px;
    }

    thead {
      &.sticky {
        th {
          position: sticky;
          top: 0;
        }
      }
      th {
        z-index: 10;
        height: 48px;
        border-bottom: 4px solid #f3f3f3;
        @extend %head-font;
        select {
          @extend %head-font;
        }
      }

      th {
        // background: rgb(235, 235, 235);
      }
    }

    td {
      input {
        max-width: 100px;
        border: none;
        background: transparent;
        border-radius: 0;
        border-bottom: solid 1px $secondary-border-color;
      }

      span {
        margin-left: 5px;
        &.prefix {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }

    th,
    td {
      padding: 12px 3px;
      div {
        margin-right: 8px;
      }

      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }

      &.min {
        width: 1%;
        white-space: nowrap;
      }
    }

    th {
      background-color: $primary-background-color;
      white-space: nowrap;
      padding: 8px 5px;
    }

    tr.group {
      background: $primary-background-color;
      @extend %primary-font;
    }

    tr:not(.group) {
      @extend %secondary-font;
      td:first-child {
        color: $primary-font-color;
      }
      td:not(:first-child) {
        color: $light-font-color;
      }
    }

    tr.selected {
      //outline: 1px solid $blue;
      box-shadow: -4px -4px 10px fade_out($primary-shadow-color, 0.85),
        4px 4px 10px fade_out($primary-shadow-color, 0.85);

      color: $secondary-font-color;
      div:not(.description) {
        // color: $secondary-font-color;
      }
    }

    .description {
      font-size: 12px;
    }

    .inputListContainer {
      display: flex;
      flex-direction: column;
    }

    tr {
      border-bottom: 4px solid #f3f3f3;
      &:last-child {
        border-bottom: unset;
      }
    }
  }
}
