.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
}

.timer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row {
  padding: 5px 0 5px 0;
}
