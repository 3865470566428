@import '../../styles/colors';
@import '../../styles/vars';

$header-height: 60px;

@mixin menuBorder {
  height: $header-height;
  border-bottom: solid 1px #ebeff2;
}

.tabs {
  width: 100%;
  height: 100%;
  position: relative;

  .logo {
    width: $tabsWidth;
    padding: 0 20px;
    display: flex;
    align-items: center;
    height: $header-height;
    @include menuBorder;
  }

  .header {
    @include menuBorder;

    display: flex;
    align-items: center;
    padding: 0 20px;
    background-color: $white;

    > div {
      width: calc(100% - 20px);
      min-width: 560px;
    }
  }

  .tabButtons {
    // box-shadow: 6px 0 18px rgba(0, 0, 0, 0.06);
    width: $tabsWidth;
    height: 100%;
    position: fixed;
    background: $white;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    z-index: 1;

    > div {
      padding: 0 $primaryPadding;
    }
  }

  .tabPanels {
    margin-left: $tabsWidth;
    background: $lightGray;
    height: 100%;
    z-index: 0;
    position: relative;
  }

  .switchButtonPanel {
    display: flex;
    align-self: center;
    flex-grow: 1;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 20px;
  }

  .logoutBtn {
    margin-top: 20px;
  }

  &.shortMode {
    .tabButtons {
      width: $tabsWidthShort;

      > div {
        width: 100%;
      }
    }

    .tabPanels {
      margin-left: $tabsWidthShort;
    }

    .headerContainer {
      .logo {
        width: $tabsWidthShort;
      }

      .header {
        width: calc(100% - #{$tabsWidthShort});
      }
    }
  }
}
