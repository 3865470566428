@import '../../styles/colors';
@import '../../styles/vars';

.button {
  //padding: 0 10px;
  border-radius: $primary-border-radius;
  color: $third-font-color;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  font-family: $secondary-font-family;
  height: 3em;
  letter-spacing: 0.01em;
  min-width: 160px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover:not([disabled]) {
    cursor: pointer;
  }

  &:disabled {
    //opacity: 0.5;
  }

  &.min {
    min-width: auto;
    padding: 12px 10px;
  }

  &.primary {
    background: $primary-button-color;
    color: $third-button-color;
    &:hover {
      background: $secondary-button-color;
    }
    &:active {
      background: $primary-button-pressed-color;
    }
    &:disabled {
      background: $primary-button-disabled-color;
    }
  }

  &.secondary {
    background: rgba($secondary-button-color, 0.1);
    color: $secondary-font-color;
    &:hover {
      background: $secondary-button-color;
      color: $third-font-color;
    }
    &:disabled {
      background: $primary-button-disabled-color;
      color: $light-font-color;
    }
  }

  &.cancel {
    border: solid 1px $secondary-button-border-color;
    background: $third-button-color;
    color: $primary-font-color;

    &:hover {
      color: $secondary-font-color;
      border: solid 1px $secondary-button-border-color;
    }
    &:disabled {
      border: solid 1px $primary-button-disabled-color;
      color: $primary-button-disabled-color;
      background: $primary-background-color;
    }
  }

  //&.blue {
  //  background-color: $blue;
  //}
  //
  //&.green {
  //  background-color: $green;
  //}
  //
  //&.red {
  //  background-color: $red;
  //}
}
