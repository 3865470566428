.root {
  display: grid;
  grid-template-rows: 6% 94%;
}

.tabs_header {
  padding-bottom: 12px;
  display: grid;
  grid-template-columns: 65% 35%;

  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #000;
  border-bottom: 1px solid #eee;
}

.tabs {
  display: grid;
  grid-template-columns: 65% 35%;
  max-height: 260px;
  overflow: hidden;
  height: 100%;
}

.tabs_tabs {
  overflow-y: scroll;
}

.tabs_content {
  padding: 6px 0px 0px 20px;
}

.tabs_title {
  padding: 6px 12px;
  cursor: pointer;
  background: #fff;
  color: #aaa;
  margin-bottom: 7px;
  border: none;
  text-align: left;
  font-family: Open Sans, sans-serif;
  border-radius: 0;
  outline: none;
  font-size: 16px;

  span {
    font-size: 12px;
  }

  &:hover {
    color: rgb(94, 94, 94);
    background: #f5f6f8;
  }
}

.tabs_title_active {
  padding: 6px 8px 6px 12px;
  background: #ebf5fd;
  color: black;
  border-right: 4px solid #0460a9;

  &:hover {
    background: #ebf5fd;
    color: black;
  }
}

.tabs_title_answered {
  color: black;
}
