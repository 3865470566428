@import '../../../styles/colors';
@import '../../../styles/vars';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: $primary-font-family;
  width: 300px;

  label {
    font-size: $largeSizeText;
    padding-left: 30px;
    position: relative;
    margin-bottom: 17px;
    margin-left: 8px;
    font-weight: normal;
    cursor: pointer;
    &.disabled {
      cursor: auto;
    }

    input {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
    }

    input:checked + .box {
      background-color: $third-background-color;
    }
    input:checked + .box:before {
      display: block;
    }
    input:disabled + .box {
      opacity: 0.3;
      cursor: auto;
    }

    .box {
      position: absolute;
      left: 0;
      top: 0;
      height: 20px;
      width: 20px;
      border: 1px solid $borderGray;
      border-radius: 3px;

      &:before {
        background-image: url('./checkmark.svg');
        content: '';
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }
}
