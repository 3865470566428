@import '../../../styles/common';
@import '../../../styles/colors';
@import '../../../styles/common.module';

.container {
  padding: 0;
  @media (max-width: 800px) {
    button {
      font-size: $largeSizeText;
    }
  }

  table {
    .last {
      color: $black;
    }
    .now {
      color: $selectRed;
    }
    .future {
      color: $inputGreen;
    }
  }

  .tableTop {
    margin-bottom: $primaryPadding;
    min-height: 92px;
    max-height: 100%;
  }
}

.fine {
  color: $red;
}

.addBtn {
  vertical-align: middle;
}

.selected {
}

.hidden {
  display: none !important;
}

.customStartMeeting {
  li {
    display: flex;
    align-items: center;
    min-height: 35px;

    div {
      margin-bottom: unset !important;
      min-width: 130px;
      margin-right: 5px;
    }

    select {
      padding-right: 20px;
      min-width: 200px;
      border: 1px solid #999;
      color: black;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url('../../../components/Icons/svg/arrow.svg') no-repeat left
        #fff;
      background-size: 12px;
      background-position-x: 95%;
      svg {
        fill: black;
      }
      &::-ms-expand {
        padding: 10px;
      }
    }
  }
}

.six_ibs_params_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow-y: scroll;
}

.six_ibs_params {
  border: 1px solid #999;
  border-radius: 4px;
  cursor: pointer;
  padding: 3px 6px;
  height: 30px;
  font-family: Open Sans, sans-serif;
  text-align: left;
  outline: none;
  white-space: nowrap;

  .grade {
    margin: 0 4px 0 2px;
  }

  span {
    font-size: 12px;
  }

  &:hover {
    background: #fff !important;
  }
}

.six_ibs_params_selected {
  background: #0460a9 !important;
  color: white;

  &:hover {
    background: #0460a9 !important;
  }
}

.estimatesTable {
  max-height: $dialogMaxHeight - 2 * $primaryPadding - $titleHeight -
    $buttonsPanelHeight;

  select {
    width: 190px;
    height: 70px;
    white-space: break-spaces;
  }
}

.priscriptionTable {
  max-height: 260px;
}

.patientsTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
  padding-top: 8px;
}
