@import '../../styles/vars';

.input {
  width: 70px;
  padding: 5px 7px;
  border: 1px solid $secondary-button-border-color;
  border-radius: 5px;
  font-size: $largeSizeText;
  line-height: 23px;
  font-family: $secondary-button-border-color;

  &:focus {
    outline: none;
    border-color: $primary-focus-border-color;
  }
}
