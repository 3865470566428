@import '../../../styles/vars';
@import '../../../styles/common';

.alignRight {
  margin-top: $primaryPadding;
}

.resultsForm {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  li:last-child {
    text-align: right;
  }
  li.liSecond {
    display: block;
    position: relative;
    margin: 0 !important;

    > div {
      position: absolute;
      top: -55px;
      left: 220px;
    }
  }
}

.estimatesTable {
  max-height: $dialogMaxHeight - 2 * $primaryPadding - $titleHeight -
    $buttonsPanelHeight;

  select {
    width: 190px;
    height: 70px;
    white-space: break-spaces;
  }
}

.priscriptionTable {
  max-height: 260px;
}

.hidden {
  display: none !important;
}

.fine {
  color: $red;
}

.six_ibs_params_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow-y: scroll;
}

.six_ibs_params {
  border: 1px solid #999;
  border-radius: 4px;
  cursor: pointer;
  padding: 3px 6px;
  height: 30px;
  font-family: Open Sans, sans-serif;
  text-align: left;
  outline: none;
  white-space: nowrap;

  .grade {
    margin: 0 4px 0 2px;
  }

  span {
    font-size: 12px;
  }

  &:hover {
    background: #fff !important;
  }
}

.six_ibs_params_selected {
  background: #0460a9 !important;
  color: white;

  &:hover {
    background: #0460a9 !important;
  }
}

.patientsTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
  padding-top: 8px;
}
