@import '../../../styles/colors';
@import '../../../styles/vars';

.topPanelIcon {
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(194, 207, 224, 0.3);
    border-radius: 50%;
    margin-right: 15px;

    svg > path {
      &[fill] {
        fill: $color-gray;
      }

      &[stroke] {
        stroke: $color-gray;
      }
    }
  }

  .title {
    font-weight: normal;
    font-size: $normalSizeText;
  }

  .value {
    font-size: $largeSizeText;
    line-height: 16px;
    margin-top: 4px;
    color: $color-table-black;
    font-weight: bold;
  }
}

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.button__exit {
  font-family: 'Open Sans', sans-serif;
  background: white;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 20px;
  color: #323c47;
  border: solid 1px #90a0b7;
  border-radius: 4px;
  box-sizing: border-box;
  max-height: 40px;
  height: 40px;
  line-height: 16px;

  &:hover {
    color: #ca302d;
    border: solid 1px #ca302d;
  }

  &:active {
    color: white;
    background: #ca302d;
  }

  &:focus {
    outline: none;
  }
}
